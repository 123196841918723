<template>
  <!--  用量表管理-->
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span class="label">用量表名称：</span>
        <Select class="iviewIptWidth250" v-model="searchData.usage_id" clearable>
          <Option v-for="(item, index) in selectData" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="label">所属医院：</span>
        <Select class="iviewIptWidth250" v-model="searchData.hospital_id" clearable>
          <Option v-for="(item, index) in hospitalData" :value="item.hospital_id" :key="index">{{ item.hospital_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="label">接收时间：</span>
        <DatePicker type="date" class="iviewIptWidth250" placeholder="请选择" @on-change="changeTime($event, 1)" clearable></DatePicker>
        <span class="label timeSpace">至</span>
        <DatePicker type="date" class="iviewIptWidth250" placeholder="请选择" v-model="searchData.end_date" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
      </FormItem>
      <br />
      <FormItem>
        <span class="label">状态：</span>
        <Select class="iviewIptWidth250" v-model="searchData.usage_status" clearable>
          <Option v-for="(item, index) in StatusList" :value="item.value" :key="index">{{ item.label }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number" :label-width="10">
        <span class="pageBtn finger btnSure" @click="searchItems()">查询</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录"> </Table>
    <!-- <tips-component showModal="post" v-if="confirmStatus" @cancleBtn="confirmStatus = false" @sureBrn="changeStatus()"></tips-component>
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="changeStatus()"></tips-component> -->
    <!-- 确认模态框 -->
    <Modal v-model="confirmStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="changeStatus">确定</span>
          <span class="pageBtn finger btnCancle" @click="confirmStatus = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 推送模态框 -->
    <Modal v-model="postStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认推送？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="changeStatus">确定</span>
          <span class="pageBtn finger btnCancle" @click="postStatus = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'billingInformation',
  components: {
    Table
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    }
  },
  data() {
    return {
      postStatus: false,
      confirmStatus: false,
      StatusList: [
        {
          value: '-2',
          label: '待确认'
        },
        {
          value: '-1',
          label: '待补充'
        },
        {
          value: '0',
          label: '待推送'
        },
        {
          value: '1',
          label: '已完成'
        }
      ],
      isLoad: false,
      no: '',
      time: '',
      /* 表格选项 */
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 100
        },
        {
          title: '用量表名称',
          key: 'name',
          align: 'center'
        },
        {
          title: '所属医院',
          key: 'hosName',
          align: 'center'
        },
        {
          title: '接收时间',
          key: 'time',
          align: 'center'
        },
        {
          title: '状态',
          align: 'center',
          width: 200,
          render: (h, param) => {
            let str = ''
            if (param.row.status == -2) {
              str = '待确认'
            } else if (param.row.status == -1) {
              str = '待补充'
            } else if (param.row.status == 0) {
              str = '待推送'
            } else {
              str = '已完成'
            }
            return h('div', [h('span', {}, str)])
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 260,
          render: (h, param) => {
            if (param.row.status == 1) {
              return h('div', [
                h(
                  'span',
                  {
                    /*  style: {
                      color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer'
                    }, */
                    class: 'tableFont',
                    on: {
                      click: () => {
                        this.output()
                      }
                    }
                  },
                  '导出'
                )
              ])
            } else if (param.row.status == 0) {
              return h('div', [
                h(
                  'span',
                  {
                    /* style: {
                      color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer'
                    }, */
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      disableUse: this.modifyAble
                    },
                    on: {
                      click: () => {
                        /* 推送 */
                        this.postStatus = true
                        this.usageStatus.usage_id = param.row.id
                        this.usageStatus.operate_type = '2'
                      }
                    }
                  },
                  '推送'
                ),
                h(
                  'span',
                  {
                    /* style: {
                      color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer',
                      marginLeft: '20px'
                    }, */
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      marginLeft20: !this.modifyAble
                    },
                    on: {
                      click: () => {
                        this.output()
                      }
                    }
                  },
                  '导出'
                )
              ])
            } else if (param.row.status == -1) {
              return h('div', [
                h(
                  'span',
                  {
                    /* style: {
                      color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer'
                    }, */
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      disableUse: this.insertAble
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row.id)
                      }
                    }
                  },
                  '补充'
                ),
                h(
                  'span',
                  {
                    /* style: {
                       color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer',
                      marginLeft: '20px'
                    }, */
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      marginLeft20: !this.insertAble
                    },
                    on: {
                      click: () => {
                        this.output()
                      }
                    }
                  },
                  '导出'
                )
              ])
            } else if (param.row.status == -2) {
              return h('div', [
                h(
                  'span',
                  {
                    /* style: {
                      color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer'
                    }, */
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      disableUse: this.modifyAble
                    },
                    on: {
                      click: () => {
                        this.confirmStatus = true
                        /* 确认 */
                        this.usageStatus.usage_id = param.row.id
                        this.usageStatus.operate_type = '1'
                      }
                    }
                  },
                  '确认'
                ),
                h(
                  'span',
                  {
                    /* style: {
                      color: '#389AFC',
                      fontSize: '14px',
                      cursor: 'pointer',
                      marginLeft: '20px'
                    }, */
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      marginLeft20: !this.modifyAble
                    },
                    on: {
                      click: () => {
                        this.output()
                      }
                    }
                  },
                  '导出'
                )
              ])
            }
          }
        }
      ],
      listData: [
        { name: 'KK439785', hosName: '武汉市中心医院', time: '2009-12-1', status: -2 },
        { name: 'KK439785', hosName: '武汉市中心医院', time: '2009-12-1', status: -1 },
        { name: 'KK439785', hosName: '武汉市中心医院', time: '2009-12-1', status: 0 }
      ] /* 查询数据 */,
      selectData: [] /* 下拉用量表名称数据 */,
      hospitalData: [] /* 医院列表下拉数据 */,
      searchData: {
        usage_id: '',
        hospital_id: '',
        begin_date: '',
        end_date: '',
        usage_status: ''
      },
      searchForm: {},
      total: 0,
      pages: {
        page: 1,
        rows: 10
      },
      usageStatus: {
        usage_id: 0,
        operate_type: 0
      },
      options: {}, // 时间设置
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false // 查找权限
    }
  },
  methods: {
    sureClick() {
      console.log('点击确认')
      this.postStatus = false
    },
    goDetails(id) {
      this.$router.push({
        path: '/addMeter',
        query: {
          id: id
        }
      })
    },
    changePage(e) {
      this.pages.page = e
      this.showList(this.page, this.searchForm)
    },
    /* 展示所有用量表 */
    showList(pages, items) {
      let page = pages
      let item = items
      let searchObj = Object.assign(page, item)
      this.$http.get(this.$api.usageList, searchObj).then(res => {
        if (res.status) {
          this.total = res.total
          let listData = []
          for (const item of res.data) {
            let obj = {}
            obj.id = item.usage_id
            obj.name = item.usage_title
            obj.hosName = item.hospital_name
            let time = item.receive_time
            time = this.$moment.unix(time).format('YYYY-MM-DD hh:mm:ss')
            obj.time = time
            if (item.usage_status === '待确认') {
              obj.status = -2
            } else if (item.usage_status === '待补充') {
              obj.status = -1
            } else if (item.usage_status === '待推送') {
              obj.status = 0
            } else {
              obj.status = 1
            }
            listData.push(obj)
          }
          // this.listData = listData
        }
      })
    },
    /* 用量表下拉(查询条件) */
    selectUsage() {
      /* 用量表名称 */
      this.$http.get(this.$api.selectUsage).then(res => {
        if (res.status) {
          for (const item of res.data) {
            let id = item.usage_id
            let name = item.usage_title
            let obj = { id, name }
            this.selectData.push(obj)
          }
        }
      })
      /* 所属医院 */
      this.$http.get(this.$api.getHospital).then(res => {
        if (res.status) {
          let hospital = res.data.filter(item => {
            return item.customer_type == 1
          })
          for (const item of hospital) {
            let hospital_id = item.customer_id
            let hospital_name = item.customer_name
            let obj = { hospital_id, hospital_name }
            this.hospitalData.push(obj)
          }
        }
      })
    },
    /* 按照条件查找用量表 */
    searchItems() {
      let begin_date = this.searchData.begin_date
      begin_date = this.$moment(begin_date).format('YYYY-MM-DD HH:mm:ss')
      if (begin_date === 'Invalid date') {
        begin_date = ''
      }
      let end_date = this.searchData.end_date
      end_date = this.$moment(end_date).format('YYYY-MM-DD HH:mm:ss')
      if (end_date === 'Invalid date') {
        end_date = ''
      }
      let usage_id = this.searchData.usage_id
      let hospital_id = this.searchData.hospital_id
      let usage_status = this.searchData.usage_status
      let obj = { usage_id, hospital_id, begin_date, end_date, usage_status }
      this.searchForm = obj
      this.showList(this.pages, obj)
    },
    changeStatus() {
      this.$http.put(this.$api.changeStatus, this.usageStatus).then(res => {
        if (res.status) {
          this.showList(this.pages, { usage_status: this.searchData.usage_status })
        } else {
          this.$Message.error(res.message)
        }
      })
      this.postStatus = false
      this.confirmStatus = false
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.searchData.begin_date = e
        that.searchData.end_date = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.searchData.begin_date)
          }
        }
      } else {
        this.searchData.end_date = e
      }
    },
    // 导出
    output() {
      this.$Message.error('功能还在开发中')
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    }
  },
  async created() {
    await this.showList(this.pages, {})
    this.selectUsage()
    let flag = this.$route.query.show
    if (flag) {
      this.$set(this.searchData, 'usage_status', '-2')
      await this.searchItems()
    }
    this.getRightVisibal()
  }
}
</script>

<style scoped lang="less">
.summary {
  height: 48px;
  line-height: 48px;
  border: 1px solid #e8eaec;
  border-top: none;
  text-align: right;
  padding-right: 23px;
}
.timeSpace {
  margin: 0px 10px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
.marginLeft20 {
  margin-left: 20px;
}
</style>
